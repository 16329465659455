import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { Icon } from '@iconify/react';
import {
  Badge,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { noCase } from 'change-case';
import {db} from '../../utils/firebase';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';
import { fToNow } from '../../utils/formatTime';

function renderContent(notification) {
  return (
    <Typography variant='subtitle2'>
      {noCase(notification.description)}
      <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.title}
      </Typography>
    </Typography>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification, getNotifications }) {
  const {
    user: { id: userId },
  } = useAuth();

  const onNotifClick = async () => {
    await db.collection('notifications')
      .doc(userId)
      .collection('webNotifications')
      .doc(notification.id)
      .update({
        isUnRead: false,
      });

    await getNotifications();
  };

  return (
    <ListItemButton
      onClick={onNotifClick}
      to={`/dashboard/taleplerim/${notification.objectId}/detay`}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={renderContent(notification)}
        secondary={
          <Typography
            variant='caption'
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt.toDate())}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isMountedRef = useIsMountedRef();
  const [notifications, setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const {
    user: { id: userId },
  } = useAuth();
  
  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, []);

  const getNotifications = useCallback(async () => {
    try {
      if (!isMountedRef.current) {
        return;
      }
      const notificationSnapshots = await db.collection('notifications')
        .doc(userId)
        .collection('webNotifications')
        .orderBy("createdAt","desc").limit(8)
        .get();

      setNotifications(
        notificationSnapshots.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })),
      );
    } catch (error) {
      console.error('Error getting offices: ', error);
    }
  }, [isMountedRef, userId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    const notifications = await db.collection('notifications')
      .doc(userId)
      .collection('webNotifications')
      .get();

    notifications.forEach(async (notification) => {
      await db.collection('notifications')
        .doc(userId)
        .collection('webNotifications')
        .doc(notification.id)
        .update({
          isUnRead: false,
        });
    });

    await getNotifications();
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size='large'
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Badge badgeContent={totalUnRead} color='error'>
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle1'>Bildirimler</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              {totalUnRead} okunmayan bildirim
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=' Mark all as read'>
              <MIconButton color='primary' onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Yeni
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              notification.isUnRead?
              <NotificationItem getNotifications={getNotifications} key={notification.id} notification={notification} />
              :''
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Okunmuş
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
               !notification.isUnRead?
              <NotificationItem getNotifications={getNotifications} key={notification.id} notification={notification} />
              :''
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
