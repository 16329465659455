import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import {db, auth as firebaseAuth} from '../utils/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebaseAuth().onAuthStateChanged(async (user) => {
        if (user) {
          const doc = await db.collection('users').doc(user.uid).get();

          if (doc.exists) {
            setProfile(doc.data());
          }

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch],
  );

  const login = (email, password) => firebaseAuth().signInWithEmailAndPassword(email, password);

  const register = (email, password, firstName, lastName, phone, office) => {
    firebaseAuth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        db.collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            phone:phone,
            ofis:office,
            role:'Personel',
          });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const logout = async () => {
    await firebaseAuth().signOut();
  };

  const resetPassword = async (email) => {
    await firebaseAuth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: profile?.role || '',
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          ofis: profile?.ofis || '',
        },
        login,
        register,
        logout,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
