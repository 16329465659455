export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIR_API_KEY,
  authDomain: process.env.REACT_APP_FIR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIR_APP_ID,
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
