import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseConfig } from 'config';

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth;
const storage = firebase.storage;

// if (process.env.REACT_APP_FIR_EMULATOR) {
//   db.useEmulator('localhost', 5004);
//   auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
//   storage().useEmulator("localhost", 9199);
//   console.log('USING FIREBASE EMULATOR');

// }

export default firebase;
export { db, auth };
