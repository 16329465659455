import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {db} from '../utils/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { v4 as uuidv4 } from 'uuid';
import useAuth from 'hooks/useAuth';

const initialState = {
  data: [],
  error: null,
  loading: false,
  newTalep: {
    error: null,
    loading: false,
  },
  currentTalep: {
    data: {},
    loading: false,
    error: null,
  },
  comments: {
    data: [],
    error: null,
    loading: true,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_TALEPLERIM':
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };
    case 'FAILED_TALEPLERIM':
      return {
        ...state,
        data: [],
        error: action.payload.error,
        loading: false,
      };
    case 'SUCCESS_TALEPLERIM':
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false,
      };
    case 'FETCH_TALEP_COMMENTS':
      return {
        ...state,
        comments: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case 'FAILED_TALEP_COMMENTS':
      return {
        ...state,
        comments: {
          data: [],
          error: action.payload.error,
          loading: false,
        },
      };
    case 'SUCCESS_TALEP_COMMENTS':
      return {
        ...state,
        comments: {
          data: action.payload.data,
          error: null,
          loading: false,
        },
      };
    case 'FETCH_TALEP_BY_ID':
      return {
        ...state,
        currentTalep: {
          data: {},
          error: null,
          loading: true,
        },
      };
    case 'FAILED_TALEP_BY_ID':
      return {
        ...state,
        currentTalep: {
          data: {},
          error: action.payload.error,
          loading: false,
        },
      };
    case 'SUCCESS_TALEP_BY_ID':
      return {
        ...state,
        currentTalep: {
          data: action.payload.data,
          error: null,
          loading: false,
        },
      };
    case 'INIT_ADD_TALEP':
      return {
        ...state,
        newTalep: {
          error: null,
          loading: true,
        },
      };
    case 'FAILED_ADD_TALEP':
      return {
        ...state,
        newTalep: {
          error: action.payload.error,
          loading: false,
        },
      };
    case 'SUCCESS_ADD_TALEP':
      return {
        ...state,
        newTalep: {
          error: null,
          loading: false,
        },
      };
    default:
      return initialState;
  }
};

const TaleplerimContext = createContext({
  ...initialState,
  getTaleplerim: () => Promise.resolve(),
  getTalepById: () => Promise.resolve(),
  getCommentsByTalepId: () => Promise.resolve(),
  addTalep: () => Promise.resolve(),
});

TaleplerimProvider.propTypes = {
  children: PropTypes.node,
};

function TaleplerimProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    user: { email },
  } = useAuth();

  const getTaleplerim = async (type) => {
    dispatch({
      type: 'FETCH_TALEPLERIM',
    });

    try {
      if (type === 'all') {
        const query = await db.collection('taleplerim').get();
        const snapshots = query.docs;
        const data = snapshots.map((snapshot) => snapshot.data());

        dispatch({
          type: 'SUCCESS_TALEPLERIM',
          payload: {
            data: data,
          },
        });
      } else if (type === 'huddle') {
        const query = await db.collection('taleplerim').where('status', '==', 'IN_HUDDLE').get();
        const snapshots = query.docs;
        const data = snapshots.map((snapshot) => snapshot.data());

        dispatch({
          type: 'SUCCESS_TALEPLERIM',
          payload: {
            data: data,
          },
        });
      } else if (type === 'pending') {
        const query = await db.collection('taleplerim').where('status', '==', 'PENDING').get();
        const snapshots = query.docs;
        const data = snapshots.map((snapshot) => snapshot.data());

        dispatch({
          type: 'SUCCESS_TALEPLERIM',
          payload: {
            data: data,
          },
        });
      } else if (type === 'inbox') {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        const query = await db.collection('taleplerim').where('createdAt', '>=', lastMonth).orderBy('createdAt', 'desc')
        .limit(250)
        .get()
        const snapshots = query.docs;
        const data = snapshots.map((snapshot) => snapshot.data());

        dispatch({
          type: 'SUCCESS_TALEPLERIM',
          payload: {
            data: data,
          },
        });
      } else {
        const query = await db.collection('taleplerim').where('addedBy', '==', email).get();
        const snapshots = query.docs;
        const data = snapshots.map((snapshot) => snapshot.data());

        dispatch({
          type: 'SUCCESS_TALEPLERIM',
          payload: {
            data: data,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'FAILED_TALEPLERIM',
        payload: { error },
      });
    }
  };

  const getTalepById = async (talepId) => {
    dispatch({
      type: 'FETCH_TALEP_BY_ID',
      payload: { talepId },
    });

    try {
      const response = await db.collection('taleplerim').doc(talepId).get();
      dispatch({
        type: 'SUCCESS_TALEP_BY_ID',
        payload: {
          data: { id: response.id, ...response.data() },
        },
      });
    } catch (error) {
      dispatch({
        type: 'FAILED_TALEP_BY_ID',
        payload: { error },
      });
    }
  };

  const getCommentsByTalepId = async (talepId) => {
    dispatch({
      type: 'FETCH_TALEP_COMMENTS',
      payload: { talepId },
    });

    try {
      const query = await db.collection('comments').where('talepId', '==', talepId).get();
      const snapshots = query.docs;
      const data = snapshots.map((snapshot) => snapshot.data());

      dispatch({
        type: 'SUCCESS_TALEP_COMMENTS',
        payload: {
          data,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'FAILED_TALEP_COMMENTS',
        payload: { error },
      });
    }
  };

  const addTalep = async (talepFormData) => {
    dispatch({
      type: 'INIT_ADD_TALEP',
    });

    db.collection('taleplerim')
      .doc(uuidv4())
      .set(talepFormData)
      .then(() => {
        dispatch({
          type: 'SUCCESS_ADD_TALEP',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FAILED_ADD_TALEP',
          payload: { error },
        });
      });
  };

  return (
    <TaleplerimContext.Provider
      value={{
        ...state,
        getTaleplerim,
        getTalepById,
        getCommentsByTalepId,
        addTalep,
      }}
    >
      {children}
    </TaleplerimContext.Provider>
  );
}

export { TaleplerimContext, TaleplerimProvider };
