import { Alert, AlertTitle, Container } from '@mui/material';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array,
  children: PropTypes.node,
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const {
    user: { role },
  } = useAuth();

  if (!accessibleRoles.includes(role)) {
    return (
      <Container>
        <Alert severity='error'>
          <AlertTitle>Yetkiniz Yok!</AlertTitle>
          Bu sayfayı görüntülemek için izniniz bulunmamakta.
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
