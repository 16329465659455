import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 512 512'>
        <defs>
          <linearGradient id='BG1' x1='100%' x2='50%' y1='9.946%' y2='50%'>
            <stop offset='0%' stopColor={PRIMARY_DARK} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id='BG2' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={PRIMARY_LIGHT} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id='BG3' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={PRIMARY_LIGHT} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path className="st0" fill="url(#BG1)" d="M286.7,388.1l177.1-286.3c33.1-58.4-90.4-69.4-127.9-9.6l-103.8,168L286.7,388.1z"/>
	      <path className="st1" fill="url(#BG2)" d="M279.6,402.2l59.7-100.5c-21.9,33.9-57.4,33.9-79.2,0l-43-67.2c-28.6-44.2-45.5-76.6-77.2-86.5
		    C94.3,133.9,27.6,137,54.5,175.1l145.9,227.1C222.3,436.1,257.7,436.1,279.6,402.2z"/>
        </g>
      </svg>
    </Box>
  );
}
